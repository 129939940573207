<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
    data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-md-0">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
          routerLink="/relics">Relics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
          routerLink="/rss">RSS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
          routerLink="/mines">Mines</a>
      </li>
      <li *ngIf="isAdmin" class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
          routerLink="/query">Query</a>
      </li>
      <li class="nav-item">
        <button class="btn nav-link" (click)="signOut()">Logout</button>
      </li>
    </ul>
  </div>
  <div class="active">{{page | uppercase}}</div>
</nav>