import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { JwtAuthService } from '../jwt-auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component implements OnInit {
  loginForm: FormGroup;

  constructor(private messageService: MessageService, private fb: FormBuilder, public jwtAuth: JwtAuthService) { }

  user = {
    username: '',
    password: ''
  };

  @ViewChild('nameRef') nameElementRef: ElementRef;


  ngOnInit() {
    this.loginForm = this.fb.group({
      username: '',
      password: ''
    });
  }

  login() {
    const formValue = this.loginForm.value;
    this.jwtAuth.login(formValue.username, formValue.password)
      .subscribe(user => {
        //
      },
        (error) => {
          this.messageService.showError(JSON.stringify(error.error.error), 'Error');
          this.loginForm.reset();
        }
      )
  }

}
