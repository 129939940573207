import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtAuthService } from '../jwt-auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public auth: JwtAuthService, public router: Router) { }

  page: string;
  isAdmin: Boolean;

  ngOnInit() {
    this.page = this.router.url.substring(1);
    this.auth.currentUser.subscribe(data => {
      this.isAdmin = data.user == "admin"
    })
  }

  signOut() {
    this.router.navigateByUrl('/login');
    this.auth.logout();
  }

}
