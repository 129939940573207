import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtAuthService } from 'src/app/jwt-auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.endsWith('login') || req.url.endsWith('register')) {
            return next.handle(req);
        }

        const idToken = JSON.parse(localStorage.getItem("currentUser")).token;

        if (!idToken) {
            return next.handle(req);
        }
        if (idToken) {
            //console.log(((idToken)))
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + idToken)
            });

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}