import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PsqlService } from '../psql.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { JwtAuthService } from '../jwt-auth.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-relics2',
  templateUrl: './relics2.component.html',
  styleUrls: ['./relics2.component.css']
})
export class Relics2Component implements OnInit, AfterViewInit {
  myForm: FormGroup;
  public displayedColumns = ['lvl', 'x', 'y', 'delete'];
  public dataSource = new MatTableDataSource<any>();
  levels = ['All', 30, 40, 50];
  levelsSelect = ['30', '40', '50'];
  lvlvalue: number;
  temp = '';
  user: {};
  constructor(public router: Router, private fb: FormBuilder, public psqlService: PsqlService,
    public auth: JwtAuthService, private messageService: MessageService) { }

  @ViewChild('nameRef') nameElementRef: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.nameElementRef.nativeElement.focus();
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.auth.currentUser.subscribe(data => {
      this.user = data;
      //console.log(this.user)
      this.psqlService.getCoords('relics', this.user)
        .subscribe(relics => {
          this.dataSource.data = relics;
          this.dataSource.sort = this.sort;
        });
    })

    this.myForm = this.fb.group({
      lvl: null,
      x: null,
      y: null
    });

    // document.getElementById('lvl').focus();

    this.dataSource.filterPredicate = (data, filter: string) => {
      return data.lvl === filter;
    };
  }

  applyFilter(filterValue: string) {
    if (filterValue === 'All') {
      filterValue = '';
    }
    this.dataSource.filter = filterValue.toString().trim().toLowerCase();
  }


  addRelic() {
    const formValue = this.myForm.value;

    if (this.validCoords(formValue)) {
      const position = formValue.x + ',' + formValue.y;

      // Check existence in the database instead of in-memory data
      this.psqlService.checkIfCoordExists("relics", position, this.user).subscribe({
        next: (existsResponse) => {
          if (existsResponse.exists) {
            // Mine already exists, show error message
            this.messageService.showError('That tile already exists. Add another.', 'Error');
            this.myForm.reset();
          } else {
            // Mine does not exist, proceed with adding it
            this.psqlService.addCoord("relics", formValue, this.user).subscribe(() => {
              this.ngOnInit();
              this.messageService.showSuccess('Added. Thank you!!', 'Notification');
              this.myForm.reset();
            });
          }
        },
        error: (err) => {
          console.error('Error checking if coordinate exists:', err);
          this.messageService.showError('An error occurred while checking the tile. Please try again.', 'Error');
        }
      });
    }
  }


  /* 
  addRelic() {
    const formValue = this.myForm.value;
    if (this.validCoords(formValue)) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].position == formValue.x + ',' + formValue.y) {
          this.messageService.showError('That tile already exists.  Add another.', 'Error');
          this.myForm.reset();
          this.myForm.controls['enhanced'].setValue('0');
          return;
        }
      }
      this.psqlService.addCoord('relics', formValue, this.user).subscribe(() => {
        this.ngOnInit();
      });
      this.messageService.showSuccess('Added.  Thank you!!', 'Notification');
      this.myForm.reset();
    }
  }
 */

  deleteRelic(id) {
    this.psqlService.deleteCoord('relics', id).subscribe(() => {
      this.ngOnInit();
    })
  }

  validCoords(data) {
    if (data.x <= 600 && data.x >= 1 && data.y >= 1 && data.y <= 600) {
      return true;
    } else {
      this.messageService.showError('Coords must fall between 1 and 600', 'Error');
      return false;
    }
  }

}
