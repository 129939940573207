import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { PsqlService } from '../psql.service';

import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { JwtAuthService } from '../jwt-auth.service';

import { MessageService } from '../message.service';

@Component({
  selector: 'app-rss2',
  templateUrl: './rss2.component.html',
  styleUrls: ['./rss2.component.css']
})
export class Rss2Component implements OnInit, AfterViewInit {
  inputForm: FormGroup;
  nearbyForm: FormGroup;
  public displayedColumns = ['base', 'x', 'y', 'sector', 'delete'];
  public dataSource = new MatTableDataSource<any>();
  user: {};
  constructor(public router: Router, private fb: FormBuilder, public psqlService: PsqlService,
    public auth: JwtAuthService, private messageService: MessageService) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('nameRef') nameElementRef: ElementRef;

  ngAfterViewInit() {
    this.nameElementRef.nativeElement.focus();
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.auth.currentUser.subscribe(data => {
      this.user = data;
      this.psqlService.getCoords('rss', this.user) //, this.user)
        .subscribe(rss => {
          this.dataSource.data = rss;
          this.dataSource.sort = this.sort;
        });
    });

    this.inputForm = this.fb.group({
      base: null,
      x: null,
      y: null,
      sector: null
    });

    this.nearbyForm = this.fb.group({
      x: null,
      y: null,
      distance: null
    });

    document.getElementById('inputBase').focus();
  }

  addRss() {
    const formValue = this.inputForm.value;
    if (this.validCoords(formValue)) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].position == formValue.x + ',' + formValue.y) {
          this.messageService.showError('That tile already exists.  Add another.', 'Error');
          this.inputForm.reset();
          this.inputForm.controls['enhanced'].setValue('0');
          return;
        }
      }
      this.psqlService.addCoord('rss', formValue, this.user).subscribe(() => {
        this.ngOnInit();
      });
      this.messageService.showSuccess('Added.  Thank you!!', 'Notification');
      this.inputForm.reset();
      document.getElementById('inputBase').focus();
    }
  }

  deleteRss(id) {
    this.psqlService.deleteCoord('rss', id).subscribe(() => {
      this.ngOnInit();
    })
  }

  validCoords(data) {
    if (data.x <= 600 && data.x >= 1 && data.y >= 1 && data.y <= 600) {
      return true;
    } else {
      this.messageService.showError('Coords must fall between 1 and 600', 'Error');
      return false;
    }
  }
  signOut() {
    this.router.navigateByUrl('/login');
    this.auth.logout();
  }

  nearbyFilter() {
    this.dataSource.filter = '';
    const formValue = this.nearbyForm.value;
    const distance = Number(this.nearbyForm.value.distance);
    if (this.validCoords(formValue)) {
      this.dataSource.filterPredicate = (data, filter: string) => {
        const awayX = Number(data.x);
        const awayY = Number(data.y);
        const homeX = Number(filter['x']);
        const homeY = Number(filter['y']);
        return Math.pow(homeX - awayX, 2) + Math.pow(homeY - awayY, 2) < Math.pow(distance, 2);
      };
      this.dataSource.filter = formValue; // x&Y of lower left
    } else {
      //console.log('bad');
    }
  }

  resetFilter() {
    this.dataSource.filter = '';
    this.nearbyForm.reset();
  }
}
