import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PsqlService } from '../psql.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.css']
})
export class QueryComponent implements OnInit, AfterViewInit {

  queryForm: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, public psqlService: PsqlService, private messageService: MessageService) { }
  public dataSource = new MatTableDataSource<any>();
  public displayedColumns = ['lvl', 'type', 'x', 'y', 'enhanced'];
  enhancements = ['0', '50', '100', '150', '200'];
  qty = 0;

  @ViewChild('nameRef') nameElementRef: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    if (this.idToken.user == 'admin') {
      console.log('you are admin')
    }
    else {
      this.messageService.showError('only for admins', 'Error')
      this.router.navigate(['/mines']);
    }
    this.dataSource.paginator = this.paginator;
  }

  idToken = JSON.parse(localStorage.getItem("currentUser"));
  ngOnInit() {
    this.psqlService.getCoords("mines", this.idToken)
      .subscribe(mine => {
        this.qty = mine.length
        this.dataSource.data = mine;
        this.dataSource.sort = this.sort;
      });


    this.queryForm = this.fb.group({
      query: 'SELECT * FROM (SELECT DISTINCT ON (position) * FROM mines) t ORDER BY RANDOM() LIMIT 30'
    })
  }

  runQuery() {
    const formValue = this.queryForm.value;
    this.psqlService.runQuery(formValue)
      .subscribe(data => {
        this.dataSource.data = data;
        this.qty = data.length
      },
        err => this.messageService.showError(err.error, 'Error'),
        () => console.log('Request complete')

      )
  }

  random() {
    this.psqlService.runQuery({ query: 'SELECT * FROM mines WHERE user_id=1 ORDER BY RANDOM() LIMIT 10' })
      .subscribe(data => {
        this.dataSource.data = data;
        this.qty = data.length
      })
  }

}
