import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JwtGuard } from './jwt.guard';
import { Mines2Component } from './mines2/mines2.component';
import { Relics2Component } from './relics2/relics2.component';
import { Rss2Component } from './rss2/rss2.component';
import { Register2Component } from './register2/register2.component';
import { Login2Component } from './login2/login2.component';
import { QueryComponent } from './query/query.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'query', component: QueryComponent },
  { path: 'login', component: Login2Component },
  { path: 'register', component: Register2Component },
  { path: 'relics', component: Relics2Component, canActivate: [JwtGuard] },
  { path: 'rss', component: Rss2Component, canActivate: [JwtGuard] },
  { path: 'mines', component: Mines2Component, canActivate: [JwtGuard] }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
