import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtAuthService } from '../jwt-auth.service';
import { PasswordValidator } from '../shared/password.validator';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-register2',
  templateUrl: './register2.component.html',
  styleUrls: ['./register2.component.css']
})
export class Register2Component implements OnInit {

  registrationForm: FormGroup;

  constructor(private messageService: MessageService, private fb: FormBuilder, public auth: JwtAuthService, public router: Router) { }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: '',
      subscribe: false
    },
      { validator: PasswordValidator });
    this.registrationForm.get('subscribe').valueChanges
      .subscribe(checkedValue => {
        const email = this.registrationForm.get('email');
        if (checkedValue) {
          email.setValidators(Validators.required);
        } else {
          email.clearValidators();
        }
        email.updateValueAndValidity();
      });
  }

  register() {
    const formValue = this.registrationForm.value;
    this.auth.register(formValue.username, formValue.password)
      .subscribe(user => {
        //
      },
        (error) => {
          this.messageService.showError(JSON.stringify(error.error), 'Error');
        }
      )
  }

  cancel(event: Event) {
    event.preventDefault();
    this.router.navigateByUrl('/login');
  }

}
