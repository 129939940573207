<app-navbar></app-navbar>
<div>
    <form [formGroup]="myForm">

        <mat-form-field class="padding">
            <mat-label>Tile Level</mat-label>
            <mat-select formControlName="lvl" required>
                <mat-option *ngFor="let lvl of levelsSelect" [value]="lvl">
                    {{lvl}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="padding">
            <input #nameRef type="tel" matInput placeholder="X" formControlName="x" required>
        </mat-form-field>

        <mat-form-field class="padding">
            <input type="tel" matInput placeholder="Y" formControlName="y" required>
        </mat-form-field>

        <button mat-stroked-button color="primary" type="submit" [disabled]="!myForm.valid" (click)="addRelic()">Submit
            Form</button>
    </form>
</div>

<mat-radio-group class="radio-group" [(ngModel)]="lvlvalue">
    <mat-radio-button class="radio-button" *ngFor="let level of levels" [value]="level"
        (click)="applyFilter(level)">{{level}}</mat-radio-button>
</mat-radio-group>

<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="lvl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LVL </th>
        <td mat-cell *matCellDef="let element"> {{element.lvl}} </td>
    </ng-container>

    <ng-container matColumnDef="x">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> X </th>
        <td mat-cell *matCellDef="let element"> {{element.x}} </td>
    </ng-container>

    <ng-container matColumnDef="y">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Y </th>
        <td mat-cell *matCellDef="let element"> {{element.y}} </td>
    </ng-container>


    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" *ngIf="element.user_name==user.user"
                (click)="deleteRelic(element.relic_id)">
                <mat-icon class="mat-18">delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>